<template>
  <div>
    <SawadeLogo :content="sawadeLogoContent" />
  </div>
</template>

<script setup lang="ts">
import { useCmsBlock } from "@shopware-pwa/composables-next";
import type { CmsBlock } from "@shopware-pwa/types";
import SawadeLogo from "@/components/cms/element/SawadeLogo.vue";

const props = defineProps<{
  content: CmsBlock;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const sawadeLogoContent = getSlotContent("sawadeLogo");
if (sawadeLogoContent?.config && sawadeLogoContent?.translated?.config) {
  sawadeLogoContent.config["backgroundColor"] = {
    value: props.content?.backgroundColor,
    source: "static",
  };

  sawadeLogoContent.translated.config["backgroundColor"] = {
    value: props.content?.backgroundColor,
    source: "static",
  };
}
</script>
